.empty {
  font-style: italic;
  color: #999;
  font-size: 0.9em;
  padding: 1.5em !important;
}

.pagination li.active {
  background-color: #06bad6;
}

@media screen and (max-width: 600px) {
  .badge,
  .header span {
    display: none;
  }
}
