nav {
  background-color: #06bad6;
}
nav ul a,
nav ul a:visited,
nav ul a:hover,
nav ul a:visited:hover {
  margin-bottom: 2px;
  color: #fcfcfc;
}
nav ul a:hover,
nav ul a:visited:hover {
  background-color: #ef476f;
}
nav ul {
  font-family: 'Raleway', cursive;
}
nav ul .toggle {
  display: none;
}

.nav-wrapper.container {
  margin-top: 0;
}
.brand-logo img {
  max-height: 40px;
  margin: 10px 0;
}

@media screen and (max-width: 992px) {
  nav {
    z-index: 9;
  }

  nav button,
  nav button:focus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0 15px;
    color: #fcfcfc;
  }

  nav button:hover {
    background-color: #ef476f;
  }

  nav ul .responsive {
    display: none;
  }

  nav.unfold ul .responsive {
    display: block;
    width: 100%;
    background-color: #06bad6;
    position: absolute;
    top: 64px;
    right: 0;
    z-index: 10;
  }
  nav.unfold ul .responsive li {
    display: block;
    width: 100%;
  }
  nav.unfold ul .responsive a {
    display: block;
  }

  nav ul .toggle {
    display: block;
    z-index: 1000;
  }
}

@media screen and (max-width: 600px) {
  nav.unfold ul .responsive {
    top: 56px;
  }
}
