.input-field .prefix.active {
  color: #06bad6;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #06bad6;
  -webkit-box-shadow: 0 1px 0 0 #06bad6;
  box-shadow: 0 1px 0 0 #06bad6;
}
input.valid:not([type]),
input.valid:not([type]):focus,
input[type='text'].valid:not(.browser-default),
input[type='text'].valid:not(.browser-default):focus,
input[type='password'].valid:not(.browser-default),
input[type='password'].valid:not(.browser-default):focus,
input[type='email'].valid:not(.browser-default),
input[type='email'].valid:not(.browser-default):focus,
input[type='url'].valid:not(.browser-default),
input[type='url'].valid:not(.browser-default):focus,
input[type='time'].valid:not(.browser-default),
input[type='time'].valid:not(.browser-default):focus,
input[type='date'].valid:not(.browser-default),
input[type='date'].valid:not(.browser-default):focus,
input[type='datetime'].valid:not(.browser-default),
input[type='datetime'].valid:not(.browser-default):focus,
input[type='datetime-local'].valid:not(.browser-default),
input[type='datetime-local'].valid:not(.browser-default):focus,
input[type='tel'].valid:not(.browser-default),
input[type='tel'].valid:not(.browser-default):focus,
input[type='number'].valid:not(.browser-default),
input[type='number'].valid:not(.browser-default):focus,
input[type='search'].valid:not(.browser-default),
input[type='search'].valid:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #06d6a0;
  -webkit-box-shadow: 0 1px 0 0 #06d6a0;
  box-shadow: 0 1px 0 0 #06d6a0;
}
input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
  + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #06bad6;
}
.btn-delete {
  margin-left: 2em;
}
.btn-delete:hover {
  background-color: #ef476f !important;
  color: #fcfcfc !important;
}
.btn-add-choice:hover,
.btn-remove-choice:hover {
  background-color: #06bad6 !important;
}
.btn-small {
  height: 32.4px;
  line-height: 32.4px;
  font-size: 13px;
}
.red-text {
  color: #ef476f !important;
}
