.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
}

.wrapper .container {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4em;
  padding-bottom: 4em;
}

.bg {
  position: relative;
}

.bg-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.bg-image {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.section-1 .btn-large:hover {
  background-color: #e04a6e;
  color: #fcfcfc;
}

.section-1 {
  min-height: 50vh;
}

.section-2,
.section-3 {
  min-height: 30vh;
}

.section-4 {
  min-height: 40vh;
}

.section-1 .bg-image {
  background-image: radial-gradient(transparent, rgba(252, 252, 252, 0.7)),
    url('../images/image1.jpg');
}
.section-1 .btn-large {
  background-color: #f9cd69;
}
.section-1 h1,
.section-1 h5 {
  color: #7c265c;
}

.section-2 {
  background-color: #7c265c;
  color: #fcfcfc;
}
.section-2 h2,
.section-2 h3,
.section-2 h4,
.section-2 h5 {
  color: #06bad6;
}
.section-2 p {
  line-height: 1.9em;
}

.section-3 .bg-image {
  background-image: radial-gradient(transparent, rgba(252, 252, 252, 0.7)),
    url('../images/image2.jpg');
}

.section-4 {
  background-color: #25547d;
  color: #fcfcfc;
}

.section-4 .btn-large {
  background-color: #56d3a0;
  color: #fcfcfc;
}

.section-4 .btn-large:hover {
  background-color: #e04a6e;
  color: #fcfcfc;
}
