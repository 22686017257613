@import url(https://fonts.googleapis.com/css?family=Raleway);
nav {
  background-color: #06bad6;
}
nav ul a,
nav ul a:visited,
nav ul a:hover,
nav ul a:visited:hover {
  margin-bottom: 2px;
  color: #fcfcfc;
}
nav ul a:hover,
nav ul a:visited:hover {
  background-color: #ef476f;
}
nav ul {
  font-family: 'Raleway', cursive;
}
nav ul .toggle {
  display: none;
}

.nav-wrapper.container {
  margin-top: 0;
}
.brand-logo img {
  max-height: 40px;
  margin: 10px 0;
}

@media screen and (max-width: 992px) {
  nav {
    z-index: 9;
  }

  nav button,
  nav button:focus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0 15px;
    color: #fcfcfc;
  }

  nav button:hover {
    background-color: #ef476f;
  }

  nav ul .responsive {
    display: none;
  }

  nav.unfold ul .responsive {
    display: block;
    width: 100%;
    background-color: #06bad6;
    position: absolute;
    top: 64px;
    right: 0;
    z-index: 10;
  }
  nav.unfold ul .responsive li {
    display: block;
    width: 100%;
  }
  nav.unfold ul .responsive a {
    display: block;
  }

  nav ul .toggle {
    display: block;
    z-index: 1000;
  }
}

@media screen and (max-width: 600px) {
  nav.unfold ul .responsive {
    top: 56px;
  }
}

footer,
.page-footer {
  background-color: #06bad6;
  line-height: 1.8rem;
}

.page-footer p {
  font-size: 0.9rem;
}

.page-footer a:hover {
  color: #ef476f !important;
}

.page-footer .nav ul {
  font-family: 'Raleway', cursive;
  font-size: 0.9rem;
}

.page-footer a,
.page-footer a:visited {
  color: #f9cd69;
}

.footer-copyright a {
  color: #ffd166;
  font-weight: bold;
}

.footer-copyright .heart {
  color: #ef476f;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
}

.wrapper .container {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4em;
  padding-bottom: 4em;
}

.bg {
  position: relative;
}

.bg-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.bg-image {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.section-1 .btn-large:hover {
  background-color: #e04a6e;
  color: #fcfcfc;
}

.section-1 {
  min-height: 50vh;
}

.section-2,
.section-3 {
  min-height: 30vh;
}

.section-4 {
  min-height: 40vh;
}

.section-1 .bg-image {
  background-image: radial-gradient(transparent, rgba(252, 252, 252, 0.7)),
    url(/static/media/image1.d90162a3.jpg);
}
.section-1 .btn-large {
  background-color: #f9cd69;
}
.section-1 h1,
.section-1 h5 {
  color: #7c265c;
}

.section-2 {
  background-color: #7c265c;
  color: #fcfcfc;
}
.section-2 h2,
.section-2 h3,
.section-2 h4,
.section-2 h5 {
  color: #06bad6;
}
.section-2 p {
  line-height: 1.9em;
}

.section-3 .bg-image {
  background-image: radial-gradient(transparent, rgba(252, 252, 252, 0.7)),
    url(/static/media/image2.438bf6de.jpg);
}

.section-4 {
  background-color: #25547d;
  color: #fcfcfc;
}

.section-4 .btn-large {
  background-color: #56d3a0;
  color: #fcfcfc;
}

.section-4 .btn-large:hover {
  background-color: #e04a6e;
  color: #fcfcfc;
}

.empty {
  font-style: italic;
  color: #999;
  font-size: 0.9em;
  padding: 1.5em !important;
}

.pagination li.active {
  background-color: #06bad6;
}

@media screen and (max-width: 600px) {
  .badge,
  .header span {
    display: none;
  }
}

.tabs {
  background: none;
}
.tabs .tab a,
.tabs .tab a.active,
.tabs .tab a.active:hover {
  color: #06bad6;
}
.tabs .tab a:hover {
  color: #ef476f;
}
.tabs .indicator {
  background-color: #06bad6;
}

.input-field .prefix.active {
  color: #06bad6;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #06bad6;
  box-shadow: 0 1px 0 0 #06bad6;
}
input.valid:not([type]),
input.valid:not([type]):focus,
input[type='text'].valid:not(.browser-default),
input[type='text'].valid:not(.browser-default):focus,
input[type='password'].valid:not(.browser-default),
input[type='password'].valid:not(.browser-default):focus,
input[type='email'].valid:not(.browser-default),
input[type='email'].valid:not(.browser-default):focus,
input[type='url'].valid:not(.browser-default),
input[type='url'].valid:not(.browser-default):focus,
input[type='time'].valid:not(.browser-default),
input[type='time'].valid:not(.browser-default):focus,
input[type='date'].valid:not(.browser-default),
input[type='date'].valid:not(.browser-default):focus,
input[type='datetime'].valid:not(.browser-default),
input[type='datetime'].valid:not(.browser-default):focus,
input[type='datetime-local'].valid:not(.browser-default),
input[type='datetime-local'].valid:not(.browser-default):focus,
input[type='tel'].valid:not(.browser-default),
input[type='tel'].valid:not(.browser-default):focus,
input[type='number'].valid:not(.browser-default),
input[type='number'].valid:not(.browser-default):focus,
input[type='search'].valid:not(.browser-default),
input[type='search'].valid:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #06d6a0;
  box-shadow: 0 1px 0 0 #06d6a0;
}
input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
  + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #06bad6;
}
.btn-delete {
  margin-left: 2em;
}
.btn-delete:hover {
  background-color: #ef476f !important;
  color: #fcfcfc !important;
}
.btn-add-choice:hover,
.btn-remove-choice:hover {
  background-color: #06bad6 !important;
}
.btn-small {
  height: 32.4px;
  line-height: 32.4px;
  font-size: 13px;
}
.red-text {
  color: #ef476f !important;
}

.container {
  margin-top: 4em;
  margin-bottom: 4em;
}

.card-title {
  /* font-style: italic; */
}

.choices {
  padding-top: 2em;
  padding-bottom: 2em;
}

.choices label,
.choices .col {
  width: 100%;
}

.choices label:hover {
  color: #333;
  font-size: 1.1em;
}

body {
  background-color: #fcfcfc;
}
a {
  color: #06bad6;
}
a:visited:hover,
a:hover {
  color: #ef476f;
}
a:visited {
  color: #7c265d;
}

.btn:hover,
.btn-large:hover {
  background-color: #06d6a0;
  color: #fcfcfc;
}

.btn:hover {
}

.teal {
  background-color: #06d6a0 !important;
}

.red {
  background-color: #ef476f !important;
}

h1,
h2,
h3,
h3,
h4,
h5 {
  font-family: 'Raleway', cursive;
}

h3 {
  margin-bottom: 1.2em;
}

